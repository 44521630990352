@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  height: 90%;

  @media (--viewportMedium) {
    height: 100%;
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 0 0 2px 0;
  /* white-space: nowrap; */
  overflow: hidden;

  @media (--viewportMedium) {
    padding: 14px 0 2px 0;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 4px 0;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @media (--viewportMedium) {
    padding: 18px 15px;
  }
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.container {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e6e6e6;
  display: grid;
  @media (--viewportMedium) {
    display: flex;
  }
}

.imageContainer {
  width: 100%;
  display: grid;
  height: 80%;
  @media (--viewportMedium) {
    width: 33%;
    height: 100%;
  }
}

.descriptionContainer {
  width: 100%;
  display: grid;
  @media (--viewportMedium) {
    width: 67%;
  }
}

.description {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #000000;
  text-overflow: ellipsis;
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  @media (--viewportMedium) {
    width: unset;
    white-space: unset;
  }
}

.tags {
  background-color: #edf5ff;
  border: solid 1px #b6bed4;
  color: #607da8;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  height: 30px;
  border-radius: 4px;
  padding: 2px 6px;
  /* flex-basis: calc(50% - 10px); */
  @media (--viewportMedium) {
    padding: 3px 11px;
    font-size: 13px;
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 7px;
  margin: 10px 0 0 0;
  @media (--viewportMedium) {
    /* display: flex; */
    grid-auto-flow: column;
    justify-content: unset;
    gap: 15px;
  }
}

.subtitle {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #808080;
}

.subDetails {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #000000;
}

.price {
  align-self: flex-end;
  color: #214b68;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.verified {
  max-width: 18px;
  margin-right: auto;
  margin-left: 5px;
  /* padding-bottom: 5px; */
}

.topChoices {
  max-width: 111px;
  margin-left: auto;
  margin-bottom: 5px;
}

.blurItem {
  filter: blur(10px);
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  height: 90%;

  @media (--viewportMedium) {
    height: 100%;
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}
